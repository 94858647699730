import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAnimateNumber from "vue-animate-number";
import "./css/common.css";
import "./css/normalize.css";
import router from "./router";
import filters from "./Utils/filter";

Vue.config.productionTip = false;
Vue.use(VueAnimateNumber);
Vue.use(ElementUI);
import Vant from 'vant';
import 'vant/lib/index.css';
// 使用插件
Vue.use(VueRouter);
Vue.use(Vant);

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});